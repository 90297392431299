* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  image-rendering: -webkit-optimize-contrast;
}

@import url('https://fonts.googleapis.com/css?family=Anton');
@import url('https://fonts.googleapis.com/css2?family=Bigelow+Rules&family=Poppins:wght@100;300;400;600;700&family=Signika:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bigelow+Rules&family=Poppins:wght@100;300;400;600;700&family=Roboto:wght@300&family=Signika:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Rubik&display=swap');

